/*
  Author: Aseem Lalfakawma <alalfakawma.github.io>
  This SCSS mixin will allow sizing of table columns in Bulma CSS Framework.
  The Bulma framework does not support this yet, this small code snippet fixes this.

  USAGE:
  * Should be applied on TH element, it controls all the columns under it *
  The class should be "is-#",
  is-1 will give the first widthamount, is-2 will give the second.
  Eg. The code below shows the widthAmounts as (1, 2.5, 3, 4 , 5, 6, 7)
  When typing <th class="is-2">, the width will be 2.5em, as the second value in widthAmounts array is 2.5
*/

$widthAmounts: (
  10,
  20,
  30,
  40,
  50,
  60,
  70
); // Just add the numbers here, you can use points too.
$widthUnit: "%"; // Add the unit here (rem|em|px|%)

@each $width in $widthAmounts {
  $i: index($widthAmounts, $width);
  .table thead th.is-#{$i} {
    width: #{$width}#{$widthUnit} !important;
  }
}
