.#{$fa-css-prefix}-home:before {
  content: fa-content($fa-var-home);
}
.#{$fa-css-prefix}-facebook:before {
  content: fa-content($fa-var-facebook);
}
.#{$fa-css-prefix}-twitter:before {
  content: fa-content($fa-var-twitter);
}
.#{$fa-css-prefix}-instagram:before {
  content: fa-content($fa-var-instagram);
}
.#{$fa-css-prefix}-quote-left:before {
  content: fa-content($fa-var-quote-left);
}
.#{$fa-css-prefix}-quote-right:before {
  content: fa-content($fa-var-quote-right);
}
.#{$fa-css-prefix}-expand-arrows-alt:before {
  content: fa-content($fa-var-expand-arrows-alt);
}
.#{$fa-css-prefix}-heart:before {
  content: fa-content($fa-var-heart);
}
.#{$fa-css-prefix}-link:before {
  content: fa-content($fa-var-link);
}
.#{$fa-css-prefix}-file-alt:before {
  content: fa-content($fa-var-file-alt);
}
.#{$fa-css-prefix}-palette:before {
  content: fa-content($fa-var-palette);
}
.#{$fa-css-prefix}-user-circle:before {
  content: fa-content($fa-var-user-circle);
}
.#{$fa-css-prefix}-circle:before {
  content: fa-content($fa-var-circle);
}
.#{$fa-css-prefix}-globe:before {
  content: fa-content($fa-var-globe);
}
.#{$fa-css-prefix}-users:before {
  content: fa-content($fa-var-users);
}
.#{$fa-css-prefix}-user-astronaut:before {
  content: fa-content($fa-var-user-astronaut);
}
.#{$fa-css-prefix}-qrcode:before {
  content: fa-content($fa-var-qrcode);
}
.#{$fa-css-prefix}-id-badge:before {
  content: fa-content($fa-var-id-badge);
}
.#{$fa-css-prefix}-star:before {
  content: fa-content($fa-var-star);
}
.#{$fa-css-prefix}-unlink:before {
  content: fa-content($fa-var-unlink);
}
.#{$fa-css-prefix}-sync:before {
  content: fa-content($fa-var-sync);
}
.#{$fa-css-prefix}-cog:before {
  content: fa-content($fa-var-cog);
}
.#{$fa-css-prefix}-hourglass:before {
  content: fa-content($fa-var-hourglass);
}
.#{$fa-css-prefix}-images:before {
  content: fa-content($fa-var-images);
}
.#{$fa-css-prefix}-exclamation-circle:before {
  content: fa-content($fa-var-exclamation-circle);
}
.#{$fa-css-prefix}-at:before {
  content: fa-content($fa-var-at);
}
.#{$fa-css-prefix}-bullseye:before {
  content: fa-content($fa-var-bullseye);
}
.#{$fa-css-prefix}-eye:before { content: fa-content($fa-var-eye); }

.#{$fa-css-prefix}-arrow-left:before { content: fa-content($fa-var-arrow-left); }
.#{$fa-css-prefix}-arrow-right:before { content: fa-content($fa-var-arrow-right); }
.#{$fa-css-prefix}-piggy-bank:before { content: fa-content($fa-var-piggy-bank); }
.#{$fa-css-prefix}-fire:before { content: fa-content($fa-var-fire); }
.#{$fa-css-prefix}-ban:before { content: fa-content($fa-var-ban); }
.#{$fa-css-prefix}-plus-circle:before { content: fa-content($fa-var-plus-circle); }